<template>
  <Layout :tituloPagina="'Soporte para ArgusBlack'">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header bg-transparent border-bottom text-uppercase text-truncate w-100">
            Soporte para ArgusBlack
          </div>
          <div class="card-body">
            Da click en el botón de Facebook Messenger que aparecerá en la parte inferior derecha-->.

            <br><br>

            <small v-show="mostrarAccionRecarga">
                ** Si no aparece da click
                <button class="btn btn-light waves-effect waves-light btn-sm" @click="refrescar()">aquí</button>
            </small>

            <!-- Messenger Plugin de chat Code -->
            <div id="fb-root"></div>
            <!-- Your Plugin de chat code -->
            <div id="fb-customer-chat" class="fb-customerchat"></div>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"

export default {
  name: 'SoporteParaArgusBlack',
  components: {
    Layout,
  },
  data() {
    return {
      chatbox: null,
      mostrarAccionRecarga: false
    }
  },
  mounted() {
    let self = this
    self.chatbox = document.getElementById('fb-customer-chat');
    self.chatbox.setAttribute("page_id", "502594219794759");
    self.chatbox.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function() {
      FB.init({
        xfbml            : true,
        version          : 'v12.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    setTimeout(function(){
        self.mostrarAccionRecarga = true
    }, 5000)
  },
  methods: {
    refrescar() {
      location.reload()
    }
  },
}
</script>